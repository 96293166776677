.home {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  min-width: 22rem;
}

.home h1 {
  font-size: 4rem;
  text-align: center;
  color: #333;
}

.home p {
  font-size: 1.1rem;
  text-align: center;
  margin: 4rem 0 10rem;
  color: #333;
  max-width: 40rem;
}
