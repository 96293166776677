.login-button {
  background-color: #ccc;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  width: fit-content;
  border-radius: 0.25rem;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  font-size: 0.9375rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.login-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-button .icon-user {
  display: inline-block;
  transition: transform 0.3s ease;
}

.login-button:hover .icon-user {
  animation: jiggle-rotate 1s ease-in-out infinite;
}

@keyframes jiggle-rotate {
  0%,
  100% {
    transform: translateX(0) rotate(0deg);
  }
  25%,
  75% {
    transform: translateX(-3px) rotate(-7deg);
  }
  50% {
    transform: translateX(3px) rotate(7deg);
  }
}

.login-button-text {
  display: none;
  transition: opacity 0.3s ease;
}

.login-button:hover {
  border-radius: 0.25rem;
  background-color: #007bff;
}

.login-button:hover .icon-user {
  padding: 0 0.5rem 0 0.25rem;
}

.login-button:hover .login-button-text {
  display: inline-block;
  opacity: 1;
}
