.header-container {
  padding: 0.5rem 0;
  text-align: center;
  color: #333;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 0.0625rem solid;
  border-image: linear-gradient(to right, transparent, #c7c7c7c5, transparent);
  border-image-slice: 1;
  background-color: whitesmoke;
  width: 100%;
  max-width: 40rem;
  z-index: 1;
}

.nav-links {
  padding-top: 0.5rem;
}

.link {
  color: #333;
  text-decoration: none;
  font-size: 1.125rem;
  transition: color 0.3s ease;
  margin: 0 0.3rem;
}

.link:hover {
  color: #007bff;
}

.activeLink {
  color: #007bff;
}
