.subtask-complete {
  bottom: -1.2rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  color: #333;
}

.subtask-complete:hover,
.icon-complete:hover {
  color: #00b300;
}
